<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="任务名称" prop="task_name" style="width: 25%">
              <a-input v-model="queryParams.task_name"></a-input>
            </a-form-model-item>
            <a-form-model-item label="自保组归属单位" prop="maintenance_unit_id" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="deptOptions.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="deptOnLoadChildren" placeholder="请选择自保单位" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
<!--              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>-->
            </div>
            <div v-show="showAdvanced" class="advanced-query">
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">自保单位管理</h3>
          <div class="table-btns">
<!--          <a-button v-if="btnList.includes('导出')" @click="exportModal(1)" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>-->
            <a-button v-if="btnList.includes('新增')" @click="addRecord('1')" style="margin-right: 10px" type="primary"><a-icon type="plus"></a-icon>新增</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="task_id" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="create_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="maintenance_group_crew" slot-scope="value" class="status-view">
            <span class="text" :title="(value && value.length) ? value.map(item => item.username).join(',') : ''">{{(value && value.length) ? value.map(item => item.username).join(',') : ''}}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.task_id">详情</a-menu-item>
                <a-menu-item v-if="btnList.includes('修改')||userInfo.usertype==0" :key="'edit-'+record.task_id">修改</a-menu-item>
                <a-menu-item v-if="btnList.includes('删除')||userInfo.usertype==0" :key="'delete-'+record.task_id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange1" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal1 :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal1>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getItemFromArrayByKey} from "U/index";
import deptselect from "@/mixins/deptselect";
import addOrEditModal from "V/facilityAndEquipment/maintenanceManagement/unit/addOrEditModal";
import addOrEditModal1 from "V/facilityAndEquipment/maintenanceManagement/unit/addOrEditModal1";
import {
  deleteMaintenanceUnitTask,
  exportMaintenanceUnitTaskExcel,
  getMaintenanceUnitTaskListByCondition
} from "A/maintenance";
export default {
  name: "selfmaintenanceunit",
  mixins: [deptselect,pagination],
  components: {
    addOrEditModal1,
  },
  data() {
    return {
      moment,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      queryParams:{
        monitorpointnum:'',
        monitorpointname:'',
        task_name:'',
        contract_num:'',
        contract_status:'',
        task_type:'',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '任务名称',
          dataIndex: 'task_name',
          key: 'task_name',
          ellipsis: true,
        },
        {
          title: '自保组归属单位',
          dataIndex: 'maintenance_unit_name',
          key: 'maintenance_unit_name',
          ellipsis: true,
        },
        {
          title: '自保组',
          dataIndex: 'maintenance_group_name',
          key: 'maintenance_group_name',
          ellipsis: true,
        },
        {
          title: '自保组长',
          dataIndex: 'maintenance_group_leader',
          key: 'maintenance_group_leader',
          ellipsis: true,
        },
        {
          title: '自保人员',
          dataIndex: 'maintenance_group_crew',
          key: 'maintenance_group_crew',
          ellipsis: true,
          scopedSlots: { customRender: 'maintenance_group_crew' },
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      userdepidCascaderSelected: [],
      maintenancedepidList:[],
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    pageTitle() {
      return "自保单位管理"
    },
    breadcrumb() {
      const pages = [{name:"设施设备管理",path:""}]
      pages.push({name:"保养管理",path:""})
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepid = val[val.length-1];
      }else {
        this.queryParams.userdepid = '';
      }
    },
    maintenancedepidList(val) {
      if(val && val.length) {
        this.queryParams.maintenancedepid = val[val.length-1];
      }else {
        this.queryParams.maintenancedepid = '';
      }
    },
  },
  created() {
    this.menu=getItemFromArrayByKey(this.operationMenuTree,"menuid",this.$route.params.menuid,"children")
    for(let i=0;i<this.menu.children.length;i++){
      this.btnList.push(this.menu.children[i].menuname)
    }
    this.getTableData()
    this.initDeptOptionsAll();
  },
  methods:{
    callback(key){
      this.resetQueryParams()
      this.getTableData(true)
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        task_type:1,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getMaintenanceUnitTaskListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected=[]
      this.maintenancedepidList=[]
      this.queryParams.userdepid=''
      this.queryParams.maintenancedepid=''
      this.$refs.queryForm.resetFields();
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let task_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'task_id', task_id);
      if(type == 'delete') {
        this.deleteConfirm(task_id, record)
      }else{
        this.showModal(type,record)
      }
    },
    showModal(type,record,task_type) {
      if (type == 'detail') {
        this.modalDetailData = record
        this.modalShowType = type
        this.modalVisible = true
      } else if (type == 'edit') {
        this.modalDetailData = record
        this.modalShowType = type
        this.modalVisible = true
      }
    },
    addRecord(task_type) {
      this.modalVisible = true
      this.modalShowType = 'add'
    },
    exportModal(task_type){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportEquipDirectories(task_type)
      }).catch(()=>{
      });
    },
    exportEquipDirectories(task_type){
      let params = {
        ...this.queryParams,
        task_type:task_type,
      }
      this.showLoading();
      exportMaintenanceUnitTaskExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.task_id);
      }).catch(()=>{

      }) ;
    },
    delete(task_id) {
      this.showLoading();
      if(task_id) {
        let params = {
          task_id
        };
        deleteMaintenanceUnitTask(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm('确定要删除选中数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(this.batchrowids);
      }).catch(()=>{

      }) ;
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      this.batchrowids = selectedRowKeys.join('|');
    },
  },
}
</script>
<style scoped>

</style>